import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import QuoteService from "../../../services/QuoteService";
import SearchableDropdown from "../../../components/SearchableDropdown";
import InputValidated from "../../../components/InputValidated";

export const MarketplaceOrderModal = ({
  modalOpen = false,
  setOpen,
  onConfirm,
}) => {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [marketplaceOrders, setMarketplaceOrders] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const valid =
    quantity > 0 &&
    selectedConfig &&
    selectedConfig.available_quantity >= quantity;

  useEffect(() => {
    async function getOrders() {
      setLoading(true);
      const qs = new QuoteService();
      const orders = await qs.getMarketplaceOrders();
      setMarketplaceOrders(orders);
      setLoading(false);
    }
    getOrders();
  }, []);

  const addToQuote = () => {
    const mqi = {
      quantity: quantity,
      marketplace_miner_config_id: selectedConfig.id,
    };
    console.log(mqi);
    onConfirm(mqi);
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 min-h-[400px]">
                <div className={"flex flex-col flex-1 h-parent"}>
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Select Available Marketplace Units
                      </Dialog.Title>
                      <div className="mt-2 mb-16">
                        {marketplaceOrders && (
                          <SearchableDropdown
                            options={marketplaceOrders}
                            className={"focus:z-10"}
                            onSelectedId={(value) =>
                              setSelectedConfig(
                                marketplaceOrders.find((x) => x.id === value),
                              )
                            }
                            displayField={"name"}
                            showLabel={false}
                            idField={"id"}
                            secondaryLabelFn={(miner) =>
                              `${miner.available_quantity} available | ${miner.hash_rate} ${miner.hash_rate_units}`
                            }
                            filterFn={(option, query) => {
                              return (
                                option.name
                                  .toLowerCase()
                                  .includes(query.toLowerCase()) ||
                                option.hash_rate_units
                                  .toLowerCase()
                                  .includes(query.toLowerCase()) ||
                                option.manufacturer
                                  .toLowerCase()
                                  .includes(query.toLowerCase())
                              );
                            }}
                          />
                        )}
                        {selectedConfig && (
                          <div className={"my-4"}>
                            <div className={"grid grid-cols-1 gap-y-4"}>
                              <InputValidated
                                min={1}
                                max={selectedConfig.available_quantity}
                                step={1}
                                defaultVal={quantity}
                                onValueChange={(value) => setQuantity(value)}
                                label={"Quantity"}
                              />
                            </div>
                            <p className={"text-sm mt-2"}>
                              There are {selectedConfig.available_quantity}{" "}
                              units for sale at the web-listed price. Enter
                              desired quantity below.
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="my-2">
                        <div className={"space-y-12"}>
                          <div className={"grid grid-cols-1 gap-y-4"}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="submit"
                      disabled={!valid}
                      className="inline-flex disabled:bg-gray-200 w-full justify-center rounded-md bg-brand hover:bg-brand-hover px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand sm:col-start-2"
                      onClick={() => addToQuote()}
                    >
                      {loading ? <LoadingIndicator /> : "Add to Quote"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MarketplaceOrderModal;
