import React, { useState } from "react";
import TableRowDisplay from "./TableRowDisplay";
import MinerEditForm from "./MinerEditForm";
import { usdFormatter } from "../../../utility/Utility";
import { Button } from "../../../components/Button";
import useFetch from "../../../hooks/useFetch";
import useEditMinerItem from "../../../hooks/useEditMinerItem";
import useDeleteMinerItem from "../../../hooks/useDeleteMinerItem";
import { Transition } from "@headlessui/react";

export default function MinerItemsTable({
  quote,
  minerConfigurations,
  refetch,
}) {
  const [newMinerActive, setNewMinerActive] = useState(false);
  const createNewMinerItem = useFetch("POST", "/miner_quote_items");
  const editMinerItem = useEditMinerItem();
  const deleteMinerItem = useDeleteMinerItem();

  const onMinerFormSubmit = async (mqi, createNew = false) => {
    const body = {
      miner_quote_item: {
        ...mqi,
        quote_id: quote.id,
      },
    };
    if (createNew) {
      await createNewMinerItem(body);
      setNewMinerActive(false);
    } else {
      await editMinerItem(mqi.id, body);
    }
    if (refetch) refetch();
  };

  const onDelete = async (mqi) => {
    await deleteMinerItem(mqi);
    if (refetch) refetch();
  };

  return (
    <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
      <colgroup>
        <col className="w-full" />
        <col />
        <col />
        <col />
      </colgroup>
      <thead className="border-b border-gray-200 text-gray-900">
        <tr>
          <th scope="col" className="px-0 py-3 font-semibold">
            Miners
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-4 text-right font-semibold sm:table-cell"
          >
            Quantity
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-6 text-right font-semibold sm:table-cell"
          >
            Price
          </th>
          <th scope="col" className="py-3 pl-8 pr-2 text-right font-semibold">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {quote.miner_quote_items?.map((item) => (
          <TableRowDisplay
            {...item}
            key={item.id}
            name={item.name || item.display_name}
            description={`${item.miner_configuration?.hash_rate} ${item.miner_configuration?.hash_rate_units} | ${item.miner_configuration?.power_usage_watts} W`}
            disabled={item.is_marketplace_order}
            quantity={item.quantity}
            minerQuoteItem={item}
            minerConfigurations={minerConfigurations}
            EditComponent={MinerEditForm}
            onSave={(mqi) => onMinerFormSubmit(mqi, false)}
            onDelete={(mqi) => onDelete(mqi)}
            showDelete={true}
            showFeeOptions={false}
            price={
              item.price > 0 ? usdFormatter.format(item.price) : "Hosting only"
            }
            total={
              item.quantity * item.price > 0
                ? usdFormatter.format(item.quantity * item.price)
                : "-"
            }
          />
        ))}
        <Transition
          show={!newMinerActive}
          enter="transition-opacity duration-75"
          as="tr"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <td colSpan={3}></td>
          <td>
            <Button
              variant="outline"
              color={"brand"}
              className={"mt-4"}
              onClick={() => setNewMinerActive(true)}
            >
              Add Miner
            </Button>
          </td>
        </Transition>
        <Transition
          show={newMinerActive}
          enter="transition-opacity duration-75"
          as="tr"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <td colSpan={4}>
            <MinerEditForm
              onSave={(mqi) => onMinerFormSubmit(mqi, true)}
              onCancel={() => setNewMinerActive(false)}
              showDelete={false}
              showFeeOptions={true}
              minerConfigurations={minerConfigurations}
            />
          </td>
        </Transition>
      </tbody>
    </table>
  );
}
