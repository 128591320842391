import React from "react";

export const RowCheckBox = ({ name, label, onValueChanged }) => {
  return (
    <>
      <div className="flex h-6 items-center">
        <input
          id={name}
          name={name}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-brand focus:ring-brand"
          onChange={(ev) => onValueChanged(ev?.target?.checked)}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={name} className="font-medium text-gray-900">
          {label}
        </label>
      </div>
    </>
  );
};

export default RowCheckBox;
