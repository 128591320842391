import React, { useState } from "react";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import useQuote from "../../../hooks/useQuote";
import Breadcrumbs from "../../../components/Breadcrumbs";
import SummaryPanel from "./SummaryPanel";
import QuoteBuilderPanel from "./QuoteBuilderPanel";
import EditQuoteModal from "./EditQuoteModal";

export default function ViewQuote() {
  const urlParams = useParams();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { quote_id } = urlParams;
  const [quote, refetchQuote] = useQuote(quote_id);

  return (
    <div className={"mx-auto max-w-7xl"}>
      <div className={"pt-4"}>
        <Breadcrumbs
          crumbs={[
            { name: "Quotes", href: "../quotes" },
            { name: `Quote #${quote?.id || ""}`, href: "#" },
          ]}
        />
      </div>
      <header className="relative isolate">
        <div
          className="absolute inset-0 -z-10 overflow-hidden"
          aria-hidden="true"
        >
          <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
        </div>
        <div className="px-4 py-6 sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
            <div className="flex items-center gap-x-6">
              <DocumentIcon
                alt=""
                className="text-center text-brand h-10 w-10 p-1 flex-none rounded-full"
              />
              <h1>
                <div className="text-sm leading-6 text-gray-500">
                  <>
                    Quote <span className="text-gray-700">#{quote?.id}</span>
                    <a
                      href={`/quotes_old/${quote?.id}`}
                      className={"text-gray-700 ml-4 underline font-sm"}
                    >
                      View Old Quote Page
                    </a>
                  </>
                </div>
                <div className="mt-1 text-base font-semibold leading-6 text-gray-900">
                  {quote.title}
                </div>
              </h1>
            </div>
            <div className="flex items-center gap-x-4 sm:gap-x-6">
              <a
                href="#"
                onClick={() => setEditModalOpen(true)}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Edit
              </a>
              {/*TODO: Switch this back to smarthost PDF that uses customer branding*/}
              <a
                href={`/quotes/${quote?.id}?format=pdf`}
                target={"_blank"}
                rel={"noreferrer"}
                className="rounded-md bg-brand px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
              >
                Send
              </a>
            </div>
          </div>
        </div>
      </header>
      <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-4">
        <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          <div className="lg:col-start-4 lg:row-end-1">
            <SummaryPanel quote={quote} refetch={refetchQuote} />
          </div>

          <div className="-mx-4 px-4 py-4 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-3 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-8">
            <QuoteBuilderPanel
              quote={quote}
              refetch={async () => await refetchQuote()}
              minerConfigurations={quote?.miner_configurations}
            />
          </div>
          <EditQuoteModal
            setOpen={setEditModalOpen}
            modalOpen={editModalOpen}
            quote={quote}
          />
        </div>
      </div>
    </div>
  );
}
