import { getJsonBody, getJsonHeaders } from "../utility/JsonUtil";

export default class QuoteService {
  async getQuote(id) {
    const response = await fetch(`/quotes/${id}?format=json`, {
      method: "GET",
      ...getJsonHeaders(),
    });
    return await response.json();
  }

  async getQuotes() {
    const response = await fetch(`/quotes?format=json`, {
      method: "GET",
      ...getJsonHeaders(),
    });
    return await response.json();
  }

  async getMarketplaceOrders() {
    const response = await fetch(`/quotes/marketplace_orders?format=json`, {
      method: "GET",
      ...getJsonHeaders(),
    });
    return await response.json();
  }

  async markAsSigned(id) {
    const response = await fetch(`/quotes/${id}/mark_as_signed?format=json`, {
      method: "POST",
      ...getJsonHeaders(),
      ...getJsonBody({}),
    });
    return await response.json();
  }

  async markAsOrdered(id, supplierId) {
    const response = await fetch(`/quotes/${id}/mark_as_ordered?format=json`, {
      method: "POST",
      ...getJsonHeaders(),
      ...getJsonBody({ supplier_invoice_id: supplierId }),
    });
    return await response.json();
  }

  async markAsPaid(id) {
    const response = await fetch(`/quotes/${id}/mark_as_paid?format=json`, {
      method: "POST",
      ...getJsonHeaders(),
      ...getJsonBody({}),
    });
    return await response.json();
  }

  async createMinerItem(quoteId, minerQuoteItem) {
    const response = await fetch(`/miner_quote_items`, {
      method: "POST",
      ...getJsonHeaders(),
      ...getJsonBody({
        miner_quote_item: { ...minerQuoteItem },
        quote_id: quoteId,
      }),
    });
    return await response.json();
  }

  async getAvailableMarketplaceOrders(minerQuoteItem) {
    const response = await fetch(
      `/miner_quote_items/${minerQuoteItem.id}/available_marketplace_orders`,
      {
        ...getJsonHeaders(),
      },
    );
    return await response.json();
  }

  async setMarketplaceOrders(minerQuoteItem, marketPlaceOrderIds) {
    const response = await fetch(
      `/miner_quote_items/${minerQuoteItem.id}/marketplace_sell_orders`,
      {
        method: "PUT",
        ...getJsonHeaders(),
        ...getJsonBody({
          marketplace_order_ids: marketPlaceOrderIds,
        }),
      },
    );
    return await response.json();
  }
}
